import React, { useState } from 'react'
import V5 from './v5'

function App() {

  const [version, setVersion] = useState(5)



  return (
    <div className="App">
      {/* {
      version === 1 ?
      <V1/> : 
      version === 2 ? 
      <V2/> : 
      version === 3 ? 
      <V3/> : 
      version === 4 ? 
      <V4/> : null
    } */}
      <V5 />
    </div>
  );
}

export default App;
