import React, { useState } from 'react'
import TextTyper from 'text-type-animation-effect-react'

import './v5.css'

import { title } from './data/content.js';


import ContactForm from './components/Form.js'

import CardWrapper from './components/ui/card-wrapper.js'


import q from './pics/q.png'
import one from './pics/1.png'
import two from './pics/2.png'
import three from './pics/3.png'
import excl from './pics/excl.png'
import gears from './pics/gears.png'

export default function V5() {


	const [loadSequens, setLoadSequense] = useState(1)




	return (
		<>
			<div id="start">
				<header className='gridHeader '>
					<div>
						<h1>
							<TextTyper text={title.toUpperCase()} interval={50} Markup={"code"} />
						</h1>
					</div>

					<div>
						<p>Web Design / Websites / Web applications / iOS Apps / Android Apps / UX Design / UI Design / Telegram Bots / Web Bots / Mobile Games / Desktop Games / Browser Games / Gamified Apps / <a href='https://barcelonacodeschool.com/corporate-training/' target='_blank' rel="noreferrer" >Custom Training</a></p>
					</div>

					<div>
						<a href='mailto:office@barcelonacodeschool.com' className='grid__child__spanTillRightEnd grid__child__vert_center'>
							<button>Email us</button>
						</a>
					</div>
				</header>
			</div>

			<div id="one">
				<div className="sticky">
					<h1>What we do</h1>
					<h2 className='color-electric-cyan'>User Experience Design</h2>
					<p>Before starting the development we can conduct the full process of User Experience (UX) and User Interface (UI) Design.</p>
					<p>From the problem you are trying to solve we can validate it, conduct the user research to understand that your idea will meet the users' needs, design the structure and architecture of your product, develop and test the best visual style and user-test the prototype.</p>
					<p>In this way you will know that what will be created is the best possible solution.</p>
				</div>
			</div>

			<div id="two">
				<div className="sticky">
					<h2 className='color-neon-magenta'>Full-Stack Engineering</h2>
					<p>Building you product for web and/or mobile platforms iOS and Android.</p>
					<p>With all the latest and modern technologies you can think of to make sure that your product is the fastest, robust and bulletproof.</p>
					<p>We have expertise and capacity to take on a project of any complexity.</p>
				</div>
			</div>

			<div id="three">
				<div className="sticky">
					<h2>Digital Solutions for the Future</h2>
					<p>Our software developing house is built on top of Barcelona Code School where we build software and train designers and developers since 2015.</p>
					<p>All the experience and expertise we have plus infinite pool of available developers and designers allow us to assign a team for your project from the start and deliver in the shortest time with the best quality possible.</p>
				</div>
			</div>


			<div id="four">
				<div className="sticky">
					<h1>How we work</h1>
					<h2>Meeting you</h2>
					<p>First you will have a thorough conversation with your Project Manager and the User Experience Designer. We will listen to you and ask a lot of questions to understand your product.</p>
					<p> We will identify all the features you need, understand your target audience, the problem you are trying to solve and find out the visual references for the esthetics of your product.</p>
				</div>
			</div>


			<div id="five">
				<div className="sticky">

					<h2>UX and UI Design</h2>
					<p>We will start working on the architecture and structure of your project. Conducting full UX research with discovery, definition, infomation architecture, sketching, testing, wireframing, prototyping and usability testing.</p>
					<p>We will test several styleguides and pick the one which was perceived as the best.</p>

				</div>
			</div>



			<div id="six">
				<div className="sticky">
					<h2>Development phase</h2>
					<p>Once the architecture of your project and the visual styles are ready and confirmed with you our software engineers will start working on it ro bring it to live.</p>
					<p>With the most suitable and modern technologies we will see through the continious integration and delivery in phases starting from the core functionality so you can begin testing it and rolling out the full version in the shortest time possible.</p>
					<p>We can take care of deployment and publishing if needed completing the cycle to the moment of your project being live on web or AppStore/Google Play.</p>
				</div>
			</div>


			<div className="project">
				<div className="sticky">
					<h1>Some products we've created</h1>
					<h2>Le Chat</h2>
					<h3>Website live chat with a mobile app</h3>
					<a href="https://lechat.app/" target="_blank" rel="noreferrer">
						<img
							className="projectIMG"
							src={"images/lechat.jpg"}
							alt={"Le Chat - website live chat system"}
						/>
					</a>
				</div>
			</div>

			<div className="project">
				<div className="sticky">
					<h2>toolboxAI.app</h2>
					<h3>Set of AI tools on demand</h3>
					<a href="https://toolboxai.app" target="_blank" rel="noreferrer">
						<img
							className="projectIMG"
							src={"images/toolboxAI.jpg"}
							alt={"toolboxAI.app - set of AI tools on demand"}
						/>
					</a>
				</div>
			</div>

			<div className="project">
				<div className="sticky">
					<h2>Good Bad Place</h2>
					<h3>Rate your landlord</h3>
					<a href="https://goodbadplace.com" target="_blank" rel="noreferrer">
						<img
							className="projectIMG"
							src={"images/goodbadplace.jpg"}
							alt={"Good Bad Place - landlords and rentals reviews platform"}
						/>
					</a>
				</div>
			</div>

			<div className="project">
				<div className="sticky">
					<h2>Barcelona Code School</h2>
					<h3>School's website</h3>
					<a href="https://barcelonacodeschool.com/" target="_blank" rel="noreferrer">
						<img
							className="projectIMG"
							src={"images/bcs.jpg"}
							alt={"Barcelona Code School"}
						/>
					</a>
				</div>
			</div>

			<div className="project">
				<div className="sticky">
					<h2>Brussels CyberSecurity Summit</h2>
					<h3>Participants landing page</h3>
					<a href="http://bxlcybersummit.surge.sh/index.html" target="_blank" rel="noreferrer">
						<img
							className="projectIMG"
							src={"images/ccb.jpg"}
							alt={"Brussels CyberSecurity Summit"}
						/>
					</a>
				</div>
			</div>

			<div className="project">
				<div className="sticky">
					<h2>Weather OS</h2>
					<h3>iOS weather app</h3>
					<a
						href="https://apps.apple.com/us/app/weather-os/id6443399556"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="projectIMG"
							src={"images/weatherOS.jpg"}
							alt={"Weather OS - a terminal themed weather app"}
						/>
					</a>
				</div>
			</div>

			<div className="project">
				<div className="sticky">
					<h2>Picky Eater Go!</h2>
					<h3>iOS/Android arcade game</h3>
					<a
						href="https://apps.apple.com/us/app/picky-eater-go/id6451353816?platform=iphone"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="projectIMG"
							src={"images/pickyEaterGo.jpg"}
							alt={"Picky Eater Go! - iOS arcade game"}
						/>
					</a>
				</div>
			</div>

			<div className="project">
				<div className="sticky">
					<h2>Guess a Word or Die</h2>
					<h3>iOS/Android word game</h3>
					<a
						href="https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="projectIMG"
							src={"images/guessaword.jpg"}
							alt={"Guess a Word or Die - iOS word game"}
						/>
					</a>
				</div>
			</div>

			<div className="project">
				<div className="sticky">
					<h2>I Have, I Need</h2>
					<h3>iOS/Android Inventory and recurring shopping list</h3>
					<a
						href="https://apps.apple.com/us/app/i-have-i-need/id1621110781"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="projectIMG"
							src={"images/ihaveineed.jpg"}
							alt={"I Have, I Need - Inventory and recurring shopping list"}
						/>
					</a>
				</div>
			</div>

			<div className="project">
				<div className="sticky">
					<h2>Anime Calculator</h2>
					<h3>Anime themed calculator app for iOS and Android</h3>
					<a
						href="https://apps.apple.com/us/app/anime-calculator/id1567378874?platform=iphone"
						target="_blank"
						rel="noreferrer"
					>
						<img
							className="projectIMG"
							src={"images/animecalc.jpg"}
							alt={"Anime themed calculator app for iOS and Android"}
						/>
					</a>
				</div>
			</div>



			<div id="seven">
				<div className="sticky">
					<ContactForm />

				</div>
			</div>







			<footer className='centered'>
				<p><a href='https://undefeatedsoftware.dev' >Undefeated Software</a> / <a href='https://barcelonacodeschool.com/' target='_blank' rel="noreferrer">Barcelona Code School</a> © {(new Date()).getFullYear()}</p>
				<address>c/ Paris 157, 08036, Barcelona, Spain</address>
			</footer>
		</>
	)
}